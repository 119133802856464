import fileDownload from 'js-file-download';
import queryString from 'query-string';
import { toast } from 'react-toastify';
import { APP_API_URL } from 'utils/constants';
import { fromDateFormator, toDateFormator } from 'utils/DateFormate';
import { request, requestExport } from 'utils/request';

import { call, delay, put, select, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';

import { actions as abuseReportActions } from '../AbuseReports/slice';
import { selectAddUserAnsData } from '../Answers/selectors';
import { actions as answerActions } from '../Answers/slice';
import { selectprofileImageFormData, selectprofileVideoFormData } from '../mediaSaga/selectors';
import { actions as mediaActions } from '../mediaSaga/slice';
import { actions as questionActions } from '../Questions/slice';
import { actions as snackbarActions } from '../Snackbar/slice';
import {
  selectAddMoreUsers, selectAddUser, selectData, selectedSelectedUserMediaInfo,
  selectIntroductionVideoQuestionId, selectRows, selectSelectedOnly,
  selectSelectedQuestionedAnswerId, selectSelectedRows, selectSentMsg, selectUserObj,
  selectUserSelectId
} from './selectors';
import { actions } from './slice';
import { add_User, InitialUserState, sendMsg, userObj } from './types';

export function* getUserRequest() {
  yield delay(500)

  const data: InitialUserState = yield select(
    selectData,
  );
  const getIsUserAdd = yield select(selectAddMoreUsers)
  const { filters, search, page_no, page_size } = data
  const { birthTo, enrollFrom, enrollTo, lastLoginFrom, lastLoginTo, loginsTo, messagesFrom, abuseFrom, abuseTo, birthFrom, followerFrom, followerTo, followingFrom, followingTo, loginsFrom, matchesFrom, matchesTo, blockedFrom, blockedTo, messagesTo, videosFrom, videosTo, orderBy, orderByField } = filters
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "search": search && search.length <= 1 ? '' : search?.toString().replaceAll('+', ''),
        "from_dob": birthFrom && fromDateFormator(birthFrom),
        "to_dob": birthTo && toDateFormator(birthTo),
        "from_enroll_date": enrollFrom && fromDateFormator(enrollFrom),
        "to_enroll_date": enrollTo && toDateFormator(enrollTo),
        "from_last_login": lastLoginFrom && fromDateFormator(lastLoginFrom),
        "to_last_login": lastLoginTo && toDateFormator(lastLoginTo),
        "from_login_count": loginsFrom,
        "to_login_count": loginsTo,
        "from_messages_count": messagesFrom,
        "to_messages_count": messagesTo,
        "from_matches_count": matchesFrom,
        "to_matches_count": matchesTo,
        "from_blocked_count": blockedFrom,
        "to_blocked_count": blockedTo,
        "from_videos_count": videosFrom,
        "to_videos_count": videosTo,
        "from_following_count": followingFrom,
        "to_following_count": followingTo,
        "from_follower_count": followerFrom,
        "to_follower_count": followerTo,
        "from_abuse_count": abuseFrom,
        "to_abuse_count": abuseTo,
        "page_no": data.addMoreUsers ? page_no + 1 : page_no,
        "page_size": page_size,
        "orderBy": orderBy,
        "orderByField": orderByField,
      }
    ),
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin`,
      options
    )

    if (data.addMoreUsers) yield put(actions.setPageNoIncrement())


    !getIsUserAdd ? yield put(actions.getUserSuccess(response))
      : yield put(actions.getUserSuccess({ ...response, getIsUserAdd: getIsUserAdd }))
  }
  catch (err: any) {
    yield put(actions.getUserFailure(err.message))
  }
}

export function* updateUserRequest(action: any) {
  yield delay(500)

  const options = {
    method: 'GET'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/users/change-status/
      ${action.payload.id.toString().trim()}`,
      options
    )
    yield put(actions.updateUserSuccess({ ...response, id: action.payload.id }))
  }
  catch (err: any) {
    yield put(actions.updateUserFailure(err))
  }
}

export function* getMsgRequest() {
  yield delay(500);
  const data: userObj = yield select(
    selectUserObj,
  )

  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "user_id": data.id,
        "page_no": 0,
        "page_size": 10
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/messages`,
      options
    )
    yield put(actions.getUserEditSuccess(response))

  }
  catch (err: any) {
    yield put(actions.getUserEditFailure(err.message))
  }
}
export function* getEditRequest() {
  yield delay(500);
  const data: userObj = yield select(
    selectUserObj,
  )
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "user_id": data.id,
        "mobile_no": data.phone,
        "email": data.email,
        "looking_for": data.looking_for,
        "is_active": data.is_active
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/edit`,
      options
    )
    if (response.message === 'MESSAGE_ADMIN_USER_EXISTS') {
      yield put(actions.getEditFailure(response.message))
    } else {
      yield put(actions.getEditSuccess(response))
      yield put(actions.changeUpdateStatusMessage("User information updated."))
    }
  }
  catch (err: any) {
    yield put(actions.getEditFailure(err.message))
  }
}
export function* getSendToSelectedMsgReques() {
  yield delay(500);
  const data: sendMsg = yield select(
    selectSentMsg,
  )
  const user = yield select(selectSelectedOnly,)
  const userArr = yield select(selectSelectedRows,)
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "users": user.length > 0 ? user : userArr,
        "title": data.title,
        "message": data.message
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/messages/send`,
      options
    )
    if (response && response.message === "MESSAGE_ADMIN_USER_SMS_SENT") {
      yield put(snackbarActions.showSnackbar({ message: "Sent Successfully" }));
      yield put(actions.getSendUserSuccess(response))
    } else {
      yield put(snackbarActions.showSnackbar({ message: "Something went wrong" }));
    }
  }
  catch (err: any) {
    // yield put(actions.getSendUserFailure(err.message))
  }
}
export function* getSendToAllMsgReques() {
  yield delay(500);
  const data: sendMsg = yield select(
    selectSentMsg,
  )
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "title": data.title,
        "message": data.message
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/messages/send`,
      options
    )
    yield put(snackbarActions.showSnackbar({ message: "Sent Successfully" }));
    yield put(actions.getSendUserSuccess(response))
    yield put(actions.getSelectedUser([]))
  }
  catch (err: any) {
    // yield put(actions.getSendUserFailure(err.message))
  }
}
export function* getExportRequest() {
  yield delay(500);

  const data: InitialUserState = yield select(
    selectData,
  );
  const { filters, search } = data
  const { birthTo, enrollFrom, enrollTo, lastLoginFrom, lastLoginTo, loginsTo, messagesFrom, abuseFrom, abuseTo, birthFrom, followerFrom, followerTo, followingFrom, followingTo, loginsFrom, matchesFrom, matchesTo, blockedFrom, blockedTo, messagesTo, videosFrom, videosTo, orderBy, orderByField } = filters

  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "search": search,
        "from_dob": birthFrom,
        "to_dob": birthTo,
        "from_enroll_date": enrollFrom,
        "to_enroll_date": enrollTo,
        "from_last_login": lastLoginFrom,
        "to_last_login": lastLoginTo,
        "from_login_count": loginsFrom,
        "to_login_count": loginsTo,
        "from_messages_count": messagesFrom,
        "to_messages_count": messagesTo,
        "from_matches_count": matchesFrom,
        "to_matches_count": matchesTo,
        "from_blocked_count": blockedFrom,
        "to_blocked_count": blockedTo,
        "from_videos_count": videosFrom,
        "to_videos_count": videosTo,
        "from_following_count": followingFrom,
        "to_following_count": followingTo,
        "from_follower_count": followerFrom,
        "to_follower_count": followerTo,
        "from_abuse_count": abuseFrom,
        "to_abuse_count": abuseTo,
        "page_no": 0,
        "page_size": data.totalUsers,
        "orderBy": orderBy,
        "orderByField": orderByField,
      }

    ),
  }
  try {
    const response = yield call(
      requestExport,
      `${APP_API_URL}/api/users/admin/export`,
      options
    )

    fileDownload(response, `dummy.xlsx`);
    yield put(actions.getExportSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getExportFailure(err.message))
  }
}
export function* getQuestionCategoryRequest() {
  yield delay(500);
  const options = {
    method: "GET",
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/categories/answer`,
      options
    )
    yield put(actions.getCategorySuccess(response))
    const introVideo = response.find(v => v.name === "Introduction video")
    yield put(actions.updateIntroductionVideoId(introVideo.id))
  } catch (error) {
    yield put(actions.getCategoryFailure(error))
  }
}
export function* getAddUserRequest() {
  yield delay(500);

  const data: add_User = yield select(
    selectAddUser,
  );
  const profileVideoData: any = yield select(
    selectprofileVideoFormData,
  );
  const addUserAnsData: any = yield select(
    selectAddUserAnsData,
  );
  const profileImageData: any = yield select(
    selectprofileImageFormData,
  );
  const options = {
    method: 'POST',
    body: JSON.stringify(
      {
        "fullName": data.fullName,
        "gender": data.gender,
        "mobile_no": "+972" + data.mobile_no,
        "email": data.email,
        // @ts-ignore
        "dob": new Date(data.dob)
      }
    ),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/admin/add`,
      options
    )
    const introductionVideoQuestionId = yield select(selectIntroductionVideoQuestionId);
    if (response.message === 'MESSAGE_ADMIN_USER_EXISTS') {
      yield put(actions.getAddfailure(response.message))
      toast.error('User already exists')
    } else {
      toast.success('User added Successfully')
      yield put(answerActions.uploadAnswerVideo({ ...addUserAnsData, questionId: introductionVideoQuestionId }))
      yield put(actions.getAddSuccess(response))
      yield put(mediaActions.addMediaProfileVideo({
        userId: response.user.id,
        file_type: '',
        media_type: '',
        title: profileVideoData.title,
        size: profileVideoData.size,
        url: profileVideoData.url
      }))
      yield put(mediaActions.addMediaProfileImage({
        userId: response.user.id,
        file_type: '',
        media_type: '',
        title: profileImageData.title,
        size: profileImageData.size,
        url: profileImageData.url
      }));
      yield put(actions.getAddClose())
    }
  }
  catch (err: any) {
    toast.error('User added Failed')
    yield put(actions.getAddfailure(err.message))
  }
}
export function* getUserProfileMediaRequest() {
  yield delay(500);


  const dataUserId: string = yield select(
    selectUserSelectId,
  );

  const options = {
    method: 'GET'
  }

  console.log(dataUserId, "dataUserId..this is id")
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/profile/${dataUserId}`,
      options
    )
    yield put(actions.getUserProfileMediaSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getUserProfileMediaFailure(err))
  }
}
export function* getDefaultQuestionIdRequest() {

  const options = {
    method: 'GET'
  }
  try {
    yield call(
      request,
      `${APP_API_URL}/api/auth/get/defaultQuestionId`,
      options
    )
    yield put(actions.getDefaultQuestionIdSuccess('62d53ac417dab9cc0848887b'))
  }
  catch (err: any) {
    yield put(actions.getDefaultQuestionIdFailure(err))
  }
}
export function* getUserCountsRequest() {
  const options = {
    method: 'POST'
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/get/count`,
      options
    )
    yield put(actions.getUserCountsSuccess(response))
    yield put(questionActions.getUserCountsSuccess(response))
    yield put(answerActions.getUserCountsSuccess(response))
    yield put(abuseReportActions.getUserCountsSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getUserCountsFailure(err))
  }
}
export function* setDisableMessageViaAdminRequest(action) {

  const options = {
    method: 'POST',
    body: JSON.stringify({ 'message': action.payload }),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/admin/message/set/admin/setMessage`,
      options
    )
    toast.success('Message Updated Successfully')
    yield put(actions.setDisableMessageViaAdminSuccess(response))
  }
  catch (err: any) {
    toast.success('Message Updation Failed')
    yield put(actions.setDisableMessageViaAdminFailure(err))
  }
}


export function* getSendMessagesRequest(action) {
  const options = {
    method: 'POST',
    body: JSON.stringify(action.payload),
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/messages`,
      options
    )
    yield put(actions.getSendMessagesSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getSendMessagesFailure(err))
  }
}
export function* getAdminDisabledMessageRequest() {
  const options = {
    method: 'GET'
  }
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/admin/message/setget/user/adminMessage`,
      options
    )
    yield put(actions.getAdminDisabledMessageSuccess(response))
  }
  catch (err: any) {
    yield put(actions.getAdminDisabledMessageFailure(err))
  }
}
export function* getSelectUsersAnsweredQuestionRequest() {
  const data: userObj = yield select(
    selectUserObj,
  )
  const filterData: InitialUserState = yield select(
    selectData,
  );
  const { page_no, page_size } = filterData
  const options = {
    method: 'GET',
  }
  const queries = queryString.stringify({
    user_id: data.id,
    page_no: page_no,
    page_size: page_size,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/allAnswer?${queries}`,
      options
    )
    yield put(actions.getSelectUserQuestionAnswersSuccess(response.data))
  }
  catch (err: any) {
    yield put(actions.getSelectUserQuestionAnswersFailure(err))
  }
}

export function* deleteAnswerRequest(action: any) {
  yield delay(500)
  const options = {
    method: 'DELETE'
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/${action.payload.id}`,
      options
    )
    yield put(actions.deleteAnswerSuccess({ ...response, deletedAnswerId: action.payload.id }))
    toast.success('Deleted successfully')
  }
  catch (err: any) {
    yield put(actions.deleteAnswerFailure(err))
  }
}

export function* getBlockedUserListRequest(action: any) {
  yield delay(500)
  const data: userObj = yield select(
    selectUserObj,
  )
  const filterData: InitialUserState = yield select(
    selectData,
  );
  const { page_size } = filterData
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    userId: data.id,
    pageNumber: 0,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/matches/blocked/users?${queries}`,
      options
    )
    yield put(actions.getBlockedUserSuccess(response.data))
    // yield put(actions.getBlockedUser())
  }
  catch (err: any) {
    yield put(actions.getBlockedUserFailure(err))
  }
}

export function* getMediaListRequest(action: any) {
  yield delay(500)
  const data: userObj = yield select(
    selectUserObj,
  )
  const filterData: InitialUserState = yield select(
    selectData,
  );
  const { page_size } = filterData
  const options = {
    method: 'GET'
  };
  const queries = queryString.stringify({
    id: data.id,
    page_no: 0,
    page_size: 100,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/user/profile/back-office/posts?${queries}`,
      options
    )
    yield put(actions.getMediaSuccess(response.data))
  }
  catch (err: any) {
    yield put(actions.getMediaFailure(err))
  }
}

// ==============================delete media===========================
export function* deleteSelectedMediaRequest(action: any) {
  yield delay(500)
  const options = {
    method: 'DELETE'
  };
  const selectedMediaId = yield select(selectedSelectedUserMediaInfo);
  try {
    yield call(
      request,
      `${APP_API_URL}/api/post/${selectedMediaId}`,
      options
    )
    yield put(actions.getMediaSuccess)
    yield put(actions.updateSelectedMediaId(''))
    yield put(actions.getMedia())
  }
  catch (err: any) {
    yield put(actions.getMediaFailure(err))
  }
}

// ==============================Unblock Users===========================
export function* unblockBlockedUserRequest(action: any) {
  yield delay(500)
  console.log("unblockBlockedUserRequest", action.payload);
  const requestBody = {
    userIdBy: action.payload.blockedBy,
    userIdTo: action.payload.user_id,
    response: 'unblocked',
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody)
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/matches/admin/reply`,
      options
    )
    console.log("SAGA", response.data);
    yield put(actions.getBlockedUser());
    toast.success('Unblocked successfully');
  }
  catch (err: any) {
    yield put(actions.getBlockedUserFailure(err));
  }
}

// ============================updateMediaStatusRequest================
export function* updateMediaStatusRequest(action: any) {
  yield delay(500)
  const options = {
    method: 'GET'
  };
  const postId = yield select(selectedSelectedUserMediaInfo);
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/post/change-status/${postId}`,
      options
    )
    if (response.message === 'MESSAGE.POST_STATUS_CHANGED') {
      yield call(action?.payload?.callback)
      yield put(actions.getSelectedUser([]))
    }
  }
  catch (err: any) {
    yield put(actions.getMediaFailure(err))
  }
}

// ============================updateStatusRequest================
export function* updateStatusForMultipleUsersRequest(action: PayloadAction<{ statusType: string }>) {
  yield delay(500)
  const user = yield select(selectSelectedOnly,)
  const userArr = yield select(selectSelectedRows,)

  const requestBody = {
    users: user.length > 0 ? user : userArr,
    actionStep: action.payload.statusType
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody)
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/change-action`,
      options
    )
    if (response.message === 'MESSAGES.USER_ACTION_CHANGED') {
      // yield put(actions.updateAproveRejectStatusSuccess("Status updated successful."))
      yield put(actions.updateAproveRejectStatusSuccess({
        message: "Status updated successful.",
        users: user.length > 0 ? user : userArr,
        statusType: action.payload.statusType
      }))
      yield put(actions.setUserPageNo(0))
      // yield put(actions.getUser())
      yield put(actions.getSelectedUser([]))
    }
  }
  catch (err: any) {
    yield put(actions.updateAproveRejectStatusFailure(err.message))
  }
}

export function* updateStatusForSingleUsersRequest(action: PayloadAction<{ id: string, statusType: string }>) {
  yield delay(500)

  const requestBody = {
    users: [action.payload.id],
    actionStep: action.payload.statusType
  }
  const options = {
    method: 'POST',
    body: JSON.stringify(requestBody)
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/users/change-action`,
      options
    )
    if (response.message === 'MESSAGES.USER_ACTION_CHANGED') {
      console.log('action changed response = ', {
        response,
        status: action.payload.statusType,
        id: [action.payload.id]
      })
      // yield put(actions.updateAproveRejectStatusSuccess())
      yield put(actions.updateAproveRejectStatusSuccess({
        message: "Status updated successful.",
        users: [action.payload.id],
        statusType: action.payload.statusType
      }))
      yield put(actions.setUserPageNo(0))
      // yield put(actions.getUser())
    }
  }
  catch (err: any) {
    yield put(actions.updateAproveRejectStatusFailure(err.message))
  }
}

// =====answered-question-enable-disable====================
export function* updateStatusOfAnswedQuestionRequest(action: PayloadAction<{ id: string, statusType: string }>) {
  yield delay(500)
  const answerId = yield select(selectSelectedQuestionedAnswerId)
  const options = {
    method: 'GET',
  };
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/api/question-answer/answers/change-status/${answerId}`,
      options
    )
    if (response.message === 'MESSAGE_ANSWER_STATUS_CHANGED') {
      yield put(actions.updateAnsweredQuestionStatusSuccess("Status updated successful."))
      yield put(actions.getSelectUserQuestionAnswers())
    } else {
      yield put(actions.updateAnsweredQuestionStatusFailure("Something went wrong."))
    }
  }
  catch (err: any) {
    yield put(actions.updateAnsweredQuestionStatusFailure(err.message))
  }
}

export function* userManagementSaga() {
  yield takeLatest(actions.getUser.type, getUserRequest)
  yield takeLatest(actions.setDisableMessageViaAdmin.type, setDisableMessageViaAdminRequest)
  yield takeLatest(actions.getAdminDisabledMessage.type, getAdminDisabledMessageRequest)
  yield takeLatest(actions.getUserEdit.type, getMsgRequest)
  yield takeLatest(actions.getEdit.type, getEditRequest)
  yield takeLatest(actions.getSendToSelectedUser.type, getSendToSelectedMsgReques)
  yield takeLatest(actions.getSendToAllUser.type, getSendToAllMsgReques)
  yield takeLatest(actions.getExport.type, getExportRequest)
  yield takeLatest(actions.getAddUser.type, getAddUserRequest)
  yield takeLatest(actions.updateUser.type, updateUserRequest)
  yield takeLatest(actions.getUserProfileMedia.type, getUserProfileMediaRequest)
  yield takeLatest(actions.getDefaultQuestionId.type, getDefaultQuestionIdRequest)
  yield takeLatest(actions.getUserCounts.type, getUserCountsRequest)
  yield takeLatest(actions.getSendMessages.type, getSendMessagesRequest)
  yield takeLatest(actions.getSelectUserQuestionAnswers.type, getSelectUsersAnsweredQuestionRequest)
  yield takeLatest(actions.deleteAnswer.type, deleteAnswerRequest)
  yield takeLatest(actions.getMedia.type, getMediaListRequest)
  yield takeLatest(actions.getBlockedUser.type, getBlockedUserListRequest)
  yield takeLatest(actions.deleteSelectedMedia.type, deleteSelectedMediaRequest)
  yield takeLatest(actions.getCategory.type, getQuestionCategoryRequest)
  yield takeLatest(actions.updateStatus.type, updateMediaStatusRequest)
  yield takeLatest(actions.updateAproveRejectForMultipleUsersStatus.type, updateStatusForMultipleUsersRequest)
  yield takeLatest(actions.updateAproveRejectForSingleUsersStatus.type, updateStatusForSingleUsersRequest)
  yield takeLatest(actions.updateAnsweredQuestionStatus.type, updateStatusOfAnswedQuestionRequest)
  yield takeLatest(actions.unblockBlockedUser.type, unblockBlockedUserRequest)
}